import React, { useContext, useState, useEffect } from 'react';
import styles from './styles/AccountSettings.module.css';
import AuthContext from '../../context/AuthContext';
import AccountSettingsSidebar from './AccountSettingsSidebar';
import ProfileSettings from './ProfileSettings';
import SecuritySettings from './SecuritySettings';
import SubscriptionManagement from './SubscriptionManagement';
import Loading from '../loading/Loading';
// import NotificationSettings from './NotificationSettings';
// import AppearanceSettings from './AppearanceSettings';

const AccountSettings = () => {
    const { user } = useContext(AuthContext);
    const [activeTab, setActiveTab] = useState('security');


    const renderContent = () => {
        switch(activeTab) {
            case 'profile':
                return <ProfileSettings />;
            case 'security':
                return <SecuritySettings />;
            case 'subscriptions':
                return <SubscriptionManagement />;
            // case 'notifications':
            //     return <NotificationSettings />;
            // case 'appearance':
            //     return <AppearanceSettings />;
            default:
                return <ProfileSettings />;
        }
    };

    if (!user) {
        return <Loading/>
    } else {
        return (
            <div className={styles.container}>
                <AccountSettingsSidebar 
                    activeTab={activeTab} 
                    setActiveTab={setActiveTab} 
                />
                <div className={styles.content}>
                    {renderContent()}
                </div>
            </div>
        );
    }

};

export default AccountSettings;