// ScenarioCardOptions.js
import React, { useEffect, useRef } from "react";
import styles from "./styles/ScenarioCardOptions.module.css";

const ScenarioCardOptions = ({
    scenario,
    onClose,
    onDeleteScenario,
    onCopyScenario,
    navigate,
    // handleOpenTransfer, // Uncomment if needed
}) => {
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                !event.target.closest(`.${styles.optionsButton}`)
            ) {
                onClose();
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className={styles.optionsDropdown} ref={dropdownRef}>
            <div
                className={styles.dropdownItem}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClose();
                    navigate(`/create/${scenario._id}`);
                }}
            >
                Edit
            </div>
            <div
                className={styles.dropdownItem}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClose();
                    navigate(`/logs/heatmap/${scenario._id}`);
                }}
            >
                Logs
            </div>
            <div
                className={styles.dropdownItem}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onCopyScenario(e, scenario._id);
                    onClose();
                }}
            >
                Copy
            </div>
            <div
                className={styles.dropdownItem}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onDeleteScenario(e, scenario._id);
                    onClose();
                }}
            >
                Delete
            </div>
            {/* Uncomment if Transfer is needed */}
            {/* <div
                className={styles.dropdownItem}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleOpenTransfer();
                    onClose();
                }}
            >
                Transfer
            </div> */}
        </div>
    );
};

export default ScenarioCardOptions;
