// config/SubscriptionPlans.js
export const SUBSCRIPTION_PLANS = {
    'premium-monthly': {
        name: 'Premium Monthly',
        priceId: 'price_1QlMCL025WqLbUMeA2wonZ7f',
        features: ['Feature 1', 'Feature 2'],
        price: '$9.99/month'
    },
    'premium-yearly': {
        name: 'Premium Yearly',
        priceId: 'price_1QmQte025WqLbUMeMdAe5dKa',
        features: ['Feature 1', 'Feature 2', 'Discount'],
        price: '$99/year'
    }
};