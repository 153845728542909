import React, { useContext, useEffect, useState } from 'react';
import styles from './styles/ClassroomAssignments.module.css';
import { ClassroomContext } from '../../context/ClassroomContext';
import ScenarioCard from '../mainlayout/body/ScenarioCard';
import ClassroomAssignmentsModal from './ClassroomAssignmentsModal';

const ClassroomAssignments = () => {
    const {
        classroom,
        fetchClassroomAssignments,
        classroomAssignments,
        isTeacher,
        deleteAssignment,
    } = useContext(ClassroomContext);

    const [showModal, setShowModal] = useState(false);
    const [editingAssignment, setEditingAssignment] = useState(null);

    useEffect(() => {
        if (classroom) {
            fetchClassroomAssignments(classroom._id);
        }
    }, [classroom, fetchClassroomAssignments]);

    const openCreateModal = () => {
        setEditingAssignment(null); // creation mode
        setShowModal(true);
    };

    const openEditModal = (assignment) => {
        setEditingAssignment(assignment);
        setShowModal(true);
    };

    const handleDelete = async (assignmentId) => {
        if (window.confirm('Are you sure you want to delete this assignment?')) {
            await deleteAssignment(assignmentId);
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {isTeacher && (
                    <button className={styles.createButton} onClick={openCreateModal}>
                        Create Assignment
                    </button>
                )}
            </div>
            {classroomAssignments.length === 0 ? (
                <p>No assignments found.</p>
            ) : (
                classroomAssignments.map((assignment) => (
                    <div key={assignment._id} className={styles.assignmentCard}>
                        <div className={styles.assignmentHeader}>
                            <span className={styles.assignedDate}>
                                Assigned On: {new Date(assignment.createdAt).toLocaleDateString()}
                            </span>
                            {assignment.dueDate && (
                                <span className={styles.dueDate}>
                                    Due: {new Date(assignment.dueDate).toLocaleDateString()}
                                </span>
                            )}
                        </div>
                        <h3 className={styles.assignmentTitle}>{assignment.title}</h3>
                        <p className={styles.assignmentDescription}>{assignment.description}</p>
                        <div className={styles.scenarioContainer}>
                            <ScenarioCard scenario={assignment.scenario} isAuthor={false} />
                        </div>
                        <div className={styles.studentsContainer}>
                            {assignment.students &&
                                assignment.students.length > 0 &&
                                assignment.students.map((student) => (
                                    <div key={student._id || student.id} className={styles.student}>
                                        {student.profilePicture && (
                                            <img
                                                src={student.profilePicture}
                                                alt={student.username}
                                                className={styles.studentAvatar}
                                            />
                                        )}
                                        <span className={styles.studentName}>{student.username}</span>
                                    </div>
                                ))}
                        </div>
                        {isTeacher && (
                            <div className={styles.teacherActions}>
                                <button
                                    className={styles.editButton}
                                    onClick={() => openEditModal(assignment)}
                                >
                                    Edit
                                </button>
                                <button
                                    className={styles.deleteButton}
                                    onClick={() => handleDelete(assignment._id)}
                                >
                                    Delete
                                </button>
                            </div>
                        )}
                    </div>
                ))
            )}
            {showModal && (
                <ClassroomAssignmentsModal onClose={closeModal} assignment={editingAssignment} />
            )}
        </div>
    );
};

export default ClassroomAssignments;
