import React, { useContext, useEffect } from 'react';
import AuthContext from '../../context/AuthContext';

function SuccessPage() {
    const { userId } = useContext(AuthContext);
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session_id');

        if (sessionId && userId) {
            fetch(`${process.env.REACT_APP_API_URL_LOCAL}/api/stripe/${userId}/activate-subscription?sessionId=${sessionId}`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' }
            })
            .then(res => res.json())
            .then(data => console.log('Subscription updated:', data))
            .catch(err => console.error('Error updating subscription:', err));
        }
    }, [userId]);

    return <div>Subscription Successful! Thank you for subscribing.</div>;
}

export default SuccessPage;
