import React from "react";
import ScenarioCard from "./ScenarioCard";
import styles from "./styles/ScenarioCarousel.module.css";

const ScenarioCarousel = ({ scenariosList }) => {
    return (
        <div className={styles.gridContainer}>
            {scenariosList.map((scenario) => (
                <ScenarioCard key={scenario._id} scenario={scenario} />
            ))}
        </div>
    );
};

export default ScenarioCarousel;
