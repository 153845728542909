// CancelSubscriptionPage.js

import React, { useContext, useEffect } from 'react';
import AuthContext from '../../context/AuthContext';


function CancelSubscriptionPage() {

    const { userId } = useContext(AuthContext);

    useEffect(() => {
        if (userId) {
            fetch(`${process.env.REACT_APP_API_URL_LOCAL}/api/stripe/cancel-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId })
            })
            .then(res => res.json())
            .then(data => console.log('Cancellation response:', data))
            .catch(err => console.error('Error cancelling subscription:', err));
        }
    }, [userId]);

    return <div>Your subscription is being cancelled...</div>;
}

export default CancelSubscriptionPage;
