import React, { useState, useContext, useEffect } from 'react';
import styles from './styles/SecuritySettings.module.css';
import AuthContext from '../../context/AuthContext';
import debounce from 'lodash.debounce';
import Spinner from '../loaders/Spinner';

const SecuritySettings = () => {
    const { user, setUser } = useContext(AuthContext);
    
    // Password Change State
    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [passwordForm, setPasswordForm] = useState({
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: ''
    });
    const [passwordError, setPasswordError] = useState(null);
    const [passwordSuccess, setPasswordSuccess] = useState(null);
    
    // Username Change State
    const [showUsernameForm, setShowUsernameForm] = useState(false);
    const [usernameForm, setUsernameForm] = useState({
        currentPassword: '',
        newUsername: ''
    });
    const [usernameAvailable, setUsernameAvailable] = useState(null);
    const [checkingUsername, setCheckingUsername] = useState(false);
    const [usernameError, setUsernameError] = useState(null);
    const [usernameSuccess, setUsernameSuccess] = useState(null);
    
    const [loading, setLoading] = useState(false);

    // Debounced Username Check
    const checkUsernameAvailability = debounce(async (username) => {
        if (!username || username === user.username) {
            setUsernameAvailable(null);
            return;
        }

        try {
            setCheckingUsername(true);
            const response = await fetch(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/users/settings/check-username?username=${encodeURIComponent(username)}`,
                { credentials: 'include' }
            );
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.errorMessage);

            setUsernameAvailable(data.available);
            setUsernameError(data.available ? null : 'Username is already taken');
        } catch (err) {
            setUsernameError('Error checking username availability');
        } finally {
            setCheckingUsername(false);
        }
    }, 700);

    // Password Change Handlers
    const handlePasswordChange = (e) => {
        setPasswordForm({
            ...passwordForm,
            [e.target.name]: e.target.value
        });
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setPasswordError(null);
        setPasswordSuccess(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/settings/change-password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(passwordForm)
            });

            const data = await response.json();
            if (!response.ok) throw new Error(data.errorMessage || 'Password change failed');

            setPasswordSuccess(data.successMessage);
            setPasswordForm({ currentPassword: '', newPassword: '', newPasswordConfirm: '' });
            setShowPasswordForm(false);
        } catch (err) {
            setPasswordError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Username Change Handlers
    const handleUsernameChange = (e) => {
        const newUsername = e.target.value;
        setUsernameForm(prev => ({ ...prev, newUsername }));
        setUsernameError(null);
        checkUsernameAvailability(newUsername);
    };

    const handleUsernameSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setUsernameError(null);
        setUsernameSuccess(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/settings/change-username`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(usernameForm)
            });

            const data = await response.json();
            if (!response.ok) throw new Error(data.errorMessage || 'Username change failed');

            setUsernameSuccess(data.successMessage);
            setUser(prev => ({ ...prev, username: data.newUsername }));
            setUsernameForm({ currentPassword: '', newUsername: '' });
            setShowUsernameForm(false);
        } catch (err) {
            setUsernameError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.securityContainer}>
            <h2>Security Settings</h2>

            {/* Password Change Section */}
            <div className={styles.section}>
                <h3>Change Password</h3>
                {!showPasswordForm ? (
                    <button 
                        className={styles.changeButton}
                        onClick={() => setShowPasswordForm(true)}
                    >
                        Change Password
                    </button>
                ) : (
                    <form onSubmit={handlePasswordSubmit} className={styles.form}>
                        <div className={styles.formGroup}>
                            <label>Current Password</label>
                            <input
                                type="password"
                                name="currentPassword"
                                value={passwordForm.currentPassword}
                                onChange={handlePasswordChange}
                                required
                            />
                        </div>

                        <div className={styles.formGroup}>
                            <label>New Password</label>
                            <input
                                type="password"
                                name="newPassword"
                                value={passwordForm.newPassword}
                                onChange={handlePasswordChange}
                                required
                            />
                        </div>

                        <div className={styles.formGroup}>
                            <label>Confirm New Password</label>
                            <input
                                type="password"
                                name="newPasswordConfirm"
                                value={passwordForm.newPasswordConfirm}
                                onChange={handlePasswordChange}
                                required
                            />
                        </div>

                        {passwordError && <div className={styles.errorMessage}>{passwordError}</div>}
                        {passwordSuccess && <div className={styles.successMessage}>{passwordSuccess}</div>}

                        <div className={styles.buttonGroup}>
                            <button
                                type="button"
                                className={styles.cancelButton}
                                onClick={() => {
                                    setPasswordForm({
                                        currentPassword: '',
                                        newPassword: '',
                                        newPasswordConfirm: ''
                                    });
                                    setShowPasswordForm(false);
                                }}
                                disabled={loading}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className={styles.submitButton}
                                disabled={loading}
                            >
                                {loading ? 'Updating...' : 'Change Password'}
                            </button>
                        </div>
                    </form>
                )}
            </div>

            {/* Username Change Section */}
            <div className={styles.section}>
                <h3>Change Username</h3>
                {!showUsernameForm ? (
                    <button 
                        className={styles.changeButton}
                        onClick={() => setShowUsernameForm(true)}
                    >
                        Change Username
                    </button>
                ) : (
                    <form onSubmit={handleUsernameSubmit} className={styles.form}>
                        <div className={styles.formGroup}>
                            <label>Current Password</label>
                            <input
                                type="password"
                                name="currentPassword"
                                value={usernameForm.currentPassword}
                                onChange={(e) => setUsernameForm(prev => ({
                                    ...prev,
                                    currentPassword: e.target.value
                                }))}
                                required
                            />
                        </div>

                        <div className={styles.formGroup}>
                            <label>New Username</label>
                            <div className={styles.usernameInputContainer}>
                                <input
                                    type="text"
                                    name="newUsername"
                                    value={usernameForm.newUsername}
                                    onChange={handleUsernameChange}
                                    required
                                />
                                {checkingUsername && (
                                    <span className={styles.checkingIndicator}><Spinner/></span>
                                )}
                                {usernameAvailable !== null && !checkingUsername && (
                                    <span className={usernameAvailable ? styles.available : styles.taken}>
                                        {usernameAvailable ? 'Available' : 'Taken'}
                                    </span>
                                )}
                            </div>
                        </div>

                        {usernameError && <div className={styles.errorMessage}>{usernameError}</div>}
                        {usernameSuccess && <div className={styles.successMessage}>{usernameSuccess}</div>}

                        <div className={styles.buttonGroup}>
                            <button
                                type="button"
                                className={styles.cancelButton}
                                onClick={() => {
                                    setUsernameForm({
                                        currentPassword: '',
                                        newUsername: ''
                                    });
                                    setShowUsernameForm(false);
                                }}
                                disabled={loading}
                            >
                                Cancel
                            </button>

                            <button
                                type="submit"
                                className={styles.submitButton}
                                disabled={loading || !usernameAvailable}
                            >
                                {loading ? 'Updating...' : 'Change Username'}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default SecuritySettings;