import React, { useContext, useEffect } from 'react';
import AuthContext from "../../../context/AuthContext";

import styles from "./styles/HomePage.module.css";
import { Helmet } from 'react-helmet';
import ScenarioCarousels from './ScenarioCarousels';
import { FaSearch } from 'react-icons/fa';

const HomePage = () => {
    const { loggedIn } = useContext(AuthContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        loggedIn === true && (
            <>
                <>
                    <ScenarioCarousels />
                </>
            </>
        )
    );
};

export default HomePage;
