import React, { useContext, useState } from 'react';
import styles from './styles/SubscriptionManagement.module.css';
import AuthContext from '../../context/AuthContext';
import { SUBSCRIPTION_PLANS } from '../stripe/SubscriptionPlans'

const SubscriptionManagement = () => {
    const { user, setUser } = useContext(AuthContext);
    const [isCancelling, setIsCancelling] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleCancelSubscription = async () => {
        if (!window.confirm('Are you sure you want to cancel your subscription?')) return;

        setIsCancelling(true);
        setError(null);
        setSuccess(null);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/stripe/cancel-subscription`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userId: user._id })
                }
            );

            if (!response.ok) throw new Error('Cancellation failed');

            const data = await response.json();
            
            // Update local user state
            setUser(prev => ({
                ...prev,
                subscription: {
                    ...prev.subscription,
                    status: 'canceled',
                    currentPeriodEnd: data.currentPeriodEnd // Update if needed
                }
            }));

            setSuccess('Subscription cancellation processed successfully');
        } catch (err) {
            setError(err.message || 'Failed to cancel subscription');
        } finally {
            setIsCancelling(false);
        }
    };

    if (!user.subscription || user.subscription.status === 'none') {
        return (
            <div className={styles.subscriptionContainer}>
                <h2>No Active Subscription</h2>
                <p>You don't have an active subscription.</p>
            </div>
        );
    }

    const planConfig = SUBSCRIPTION_PLANS[user.subscription.planName] || {};
    const renewalDate = new Date(user.subscription.currentPeriodEnd).toLocaleDateString();

    return (
        <div className={styles.subscriptionContainer}>
            <h2>Your Subscription</h2>
            
            {error && <div className={styles.errorAlert}>{error}</div>}
            {success && <div className={styles.successAlert}>{success}</div>}

            <div className={styles.subscriptionCard}>
                <div className={styles.planDetails}>
                    <h3>{planConfig.name || 'Premium Plan'}</h3>
                    <p>Status: {user.subscription.status}</p>
                    <p>Renews on: {renewalDate}</p>
                    <p>{planConfig.price || '$9.99/month'}</p>
                </div>
                
                {user.subscription.status === 'active' && (
                    <button 
                        onClick={handleCancelSubscription}
                        className={styles.cancelButton}
                        disabled={isCancelling}
                    >
                        {isCancelling ? 'Processing...' : 'Cancel Subscription'}
                    </button>
                )}
            </div>
        </div>
    );
};

export default SubscriptionManagement;