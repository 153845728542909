import React from 'react';
import styles from './styles/AccountSettingsSidebar.module.css';

const AccountSettingsSidebar = ({ activeTab, setActiveTab }) => {
  const tabs = [
    // { id: 'profile', label: 'Profile' },
    { id: 'security', label: 'Security' },
    { id: 'subscriptions', label: 'Subscriptions' },
    // { id: 'notifications', label: 'Notifications' },
    // { id: 'appearance', label: 'Appearance' },
  ];

  return (
    <div className={styles.sidebar}>
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`${styles.tabButton} ${activeTab === tab.id ? styles.active : ''}`}
          onClick={() => setActiveTab(tab.id)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default AccountSettingsSidebar;