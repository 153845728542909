import React, { useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import AuthContext from '../../context/AuthContext';
import styles from './styles/StripeCheckout.module.css';
import { SUBSCRIPTION_PLANS } from './SubscriptionPlans';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function StripeCheckout() {
    const { loggedIn } = useContext(AuthContext);

    async function handleSubscribe(planKey) {
        if (!loggedIn) {
            console.error('User must be logged in to subscribe.');
            // Consider adding a state to show error message to user
            return;
        }

        const plan = SUBSCRIPTION_PLANS[planKey];
        
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/stripe/create-checkout-session`, 
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        priceId: plan.priceId,
                        planName: plan.name
                    })
                }
            );

            const data = await response.json();
            
            if (data.url) {
                window.location.href = data.url;
            } else {
                console.error('No URL returned from Stripe session creation:', data);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    }

    return (
        <div className={styles.container}>
            <h1 className={styles.header}>Choose Your Plan</h1>
            <div className={styles.plansContainer}>
                {Object.entries(SUBSCRIPTION_PLANS).map(([planKey, plan]) => (
                    <div key={planKey} className={styles.planCard}>
                        <div className={styles.planContent}>
                            <h2 className={styles.planName}>{plan.name}</h2>
                            <div className={styles.price}>{plan.price}</div>
                            <ul className={styles.featuresList}>
                                {plan.features.map((feature, index) => (
                                    <li key={index} className={styles.featureItem}>
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                            <button 
                                onClick={() => handleSubscribe(planKey)}
                                className={styles.subscribeButton}
                            >
                                Subscribe Now
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default StripeCheckout;