import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styles from "./styles/ScenarioCardTags.module.css";

const ScenarioCardTags = ({ tags, searchQuery, handleTagClick, highlightText }) => {
    const maxVisible = 3;
    const [showExtra, setShowExtra] = useState(false);
    const extraRef = useRef(null);
    const triggerRef = useRef(null);
    const [dropdownStyles, setDropdownStyles] = useState({});

    const visibleTags = tags.slice(0, maxVisible);
    const extraTags = tags.slice(maxVisible);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Close if clicking outside both the trigger and the dropdown
            if (
                extraRef.current &&
                !extraRef.current.contains(event.target) &&
                triggerRef.current &&
                !triggerRef.current.contains(event.target)
            ) {
                setShowExtra(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (showExtra && triggerRef.current) {
            const rect = triggerRef.current.getBoundingClientRect();
            setDropdownStyles({
                position: "absolute",
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
                zIndex: 1000,
            });
        }
    }, [showExtra]);

    const dropdownContent = (
        <div className={styles.extraTagsDropdown} ref={extraRef} style={dropdownStyles}>
            {extraTags.map((tag, idx) => (
                <div
                    key={idx}
                    className={styles.dropdownTag}
                    onClick={() => {
                        handleTagClick(tag);
                        setShowExtra(false);
                    }}
                >
                    {highlightText(tag, searchQuery)}
                </div>
            ))}
        </div>
    );

    return (
        <div className={styles.tagSpan}>
            {visibleTags.map((tag, index) => (
                <div
                    key={index}
                    className={styles.tagDiv}
                    onClick={() => handleTagClick(tag)}
                >
                    <div className={styles.tagText}>
                        {highlightText(tag, searchQuery)}
                    </div>
                </div>
            ))}
            {extraTags.length > 0 && (
                <div
                    className={styles.tagDiv}
                    onClick={() => setShowExtra(!showExtra)}
                    ref={triggerRef}
                >
                    <div className={styles.tagText}>...</div>
                    {showExtra &&
                        ReactDOM.createPortal(dropdownContent, document.body)}
                </div>
            )}
        </div>
    );
};

export default ScenarioCardTags;
