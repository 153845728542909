import React from 'react';
import styles from './styles/Spinner.module.css';

const Spinner = () => {
    return (
        <div className={styles.spinner} />
    );
};

export default Spinner;
