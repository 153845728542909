import React from 'react'

const GlobalStylesTesting = () => {
    return (
        <div>
        
        </div>
    )
}

export default GlobalStylesTesting
