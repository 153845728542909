import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

const AuthContext = createContext();

function AuthContextProvider(props) {
    const [loggedIn, setLoggedIn] = useState(undefined);
    const [userId, setUserId] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);

    const getLoggedIn = async () => {
        try {
            setLoading(true);
            const loggedInRes = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/users/loggedIn`, { withCredentials: true });
            
            if (loggedInRes.data.loggedIn && loggedInRes.data.user) {
                setLoggedIn(true);
                setUserId(loggedInRes.data.user._id);
                setUser(loggedInRes.data.user);
            } else {
                setLoggedIn(false);
                setUserId(null);
                setUser(null);
            }
        } catch (error) {
            console.error('Error fetching loggedIn status:', error);
            setLoggedIn(false);
            setUserId(null);
            setUser(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getLoggedIn();
    }, []);

    return (
        <AuthContext.Provider
            value={{
                loggedIn,
                getLoggedIn,
                userId,
                user,
                setUser,
                loading,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContext;
export { AuthContextProvider };
