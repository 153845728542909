import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles/ScenarioCard.module.css";
import blankImage from "../../../images/blankimage.png";
import shareImage from "../../../images/share.png";
import viewsImage from "../../../images/eye.png";
import favorite from "../../../images/Favorite.png";
import favoriteOutline from "../../../images/FavoriteOutline.png";
import { calculateTimeSince } from "../../../utils/utils";
import axios from "axios";
import AuthContext from "../../../context/AuthContext";
import ShareModal from "./ShareModal";
import TransferModal from "./TransferModal";
import ScenarioCardOptions from "./ScenarioCardOptions";
import ScenarioCardTags from "./ScenarioCardTags";
import { FaEye, FaHeart, FaHeartBroken, FaRegHeart } from 'react-icons/fa';

const ScenarioCard = ({
    scenario,
    isAuthor,
    onDeleteScenario,
    onCopyScenario,
    searchQuery,
}) => {
    const navigate = useNavigate();
    const scenarioId = scenario._id;
    const { userId } = useContext(AuthContext);

    const [likesCount, setLikesCount] = useState();
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [copySuccess, setCopySuccess] = useState("");
    const [openedDropdown, setOpenedDropdown] = useState(false);
    const [isHovered, setHovered] = useState(false);
    const [hasBeenLiked, setHasBeenLiked] = useState(false);

    useEffect(() => {
        setLikesCount(scenario.likedBy.length);
        if (userId) {
            setHasBeenLiked(scenario.likedBy.includes(userId));
        }
    }, [scenario.likedBy, userId]);

    const handleClickLikeScenario = async () => {
        if (scenarioId && userId) {
            try {
                await axios.patch(
                    `${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/likeScenario/${scenarioId}`
                );

                if (hasBeenLiked) {
                    setLikesCount((prev) => prev - 1);
                } else {
                    setLikesCount((prev) => prev + 1);
                }
                setHasBeenLiked(!hasBeenLiked);
            } catch (error) {
                console.error("Error liking scenario: ", error);
            }
        }
    };

    const highlightText = (text, query) => {
        if (!query) {
            return text;
        }
        const parts = text.split(new RegExp(`(${query})`, "gi"));
        return parts.map((part, index) =>
            part.toLowerCase() === query.toLowerCase() ? (
                <span key={index} className={styles.highlight}>
                    {part}
                </span>
            ) : (
                part
            )
        );
    };

    if (!isImageLoaded) {
        return (
            <img
                src={scenario.image ? scenario.image : blankImage}
                className={styles.image}
                style={{ display: "none" }}
                onLoad={() => setIsImageLoaded(true)}
                alt=""
            />
        );
    }

    const shareUrl = `${window.location.origin}/view/${scenario._id}`;

    const handleTagClick = (tag) => {
        navigate(`/browse/${tag}`);
    };

    return (
        <>
            <div className={styles.card}>
                {isAuthor && (
                    <div className={styles.optionsButtonContainer}>
                        <button
                            className={styles.optionsButton}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setOpenedDropdown(
                                    scenario._id === openedDropdown ? null : scenario._id
                                );
                            }}
                        >
                            ⋮
                        </button>
                        {openedDropdown === scenario._id && (
                            <ScenarioCardOptions
                                scenario={scenario}
                                onClose={() => setOpenedDropdown(false)}
                                onDeleteScenario={onDeleteScenario}
                                onCopyScenario={onCopyScenario}
                                navigate={navigate}
                            />
                        )}
                    </div>
                )}

                {/* Main content is wrapped in a "content" container.
                    If the scenario is unpublished, we add the "unpublished" class
                    to grey out the content—but this does not affect the options button. */}
                <div className={`${styles.content} ${!scenario.isPublished ? styles.unpublished : ""}`}>
                    {!scenario.isPublished ? (
                        <div className={styles.tooltipContainer}>
                            <img
                                src={scenario.image ? scenario.image : blankImage}
                                alt="Scenario"
                                className={styles.image}
                            />
                            <span className={styles.exclamationIcon}>!</span>
                            <div className={styles.tooltipText}>
                                This Scenario is not published
                            </div>
                        </div>
                    ) : (
                        <img
                            src={scenario.image ? scenario.image : blankImage}
                            alt="Scenario"
                            className={styles.image}
                            onClick={() => navigate(`/view/${scenario._id}`)}
                            style={{ cursor: "pointer" }}
                        />
                    )}
                    <div className={styles.body}>
                        <div className={styles.titleContainer}>
                            <div
                                className={`${styles.title} ${isHovered ? styles.hovered : ""}`}
                                onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                                title={scenario.title}
                                onClick={() => {
                                    if (scenario.isPublished) {
                                        navigate(`/view/${scenario._id}`);
                                    }
                                }}
                            >
                                {highlightText(scenario.title || "Untitled", searchQuery)}
                            </div>
                            <img
                                src={shareImage}
                                className={styles.share}
                                onClick={
                                    scenario.isPublished
                                        ? (e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              setShowShareModal(true);
                                          }
                                        : undefined
                                }
                            />
                        </div>
                        <div className={styles.userDateContainer}>
                            <div className={styles.username}>
                                <a
                                    href={`/user/${scenario.creator?.username}`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        navigate(`/user/${scenario.creator?.username}`);
                                    }}
                                >
                                    {scenario.creator?.username}
                                </a>
                            </div>
                            {/* <div className={styles.date}>
                                {calculateTimeSince(scenario.createdAt)} ago
                            </div> */}
                        </div>
                        <div className={styles.viewsLikes}>
                            <div className={styles.like}>
                                {userId ? (
                                    hasBeenLiked ? (
                                        <FaHeart
                                            src={favorite}
                                            className={styles.likesImage}
                                            onClick={
                                                scenario.isPublished
                                                    ? handleClickLikeScenario
                                                    : undefined
                                            }
                                        />
                                    ) : (
                                        <FaRegHeart
                                            src={favoriteOutline}
                                            className={styles.likesImage}
                                            onClick={
                                                scenario.isPublished
                                                    ? handleClickLikeScenario
                                                    : undefined
                                            }
                                        />
                                    )
                                ) : (
                                    <FaHeart src={favorite} className={styles.likesImage} />
                                )}
                                <div className={styles.likeCount}>{likesCount}</div>
                            </div>
                            <div className={styles.views}>
                                <FaEye className={styles.viewsImage} />
                                {scenario.views}
                            </div>
                        </div>
                    </div>
                    {scenario.tags && (
                        <ScenarioCardTags
                            tags={scenario.tags}
                            searchQuery={searchQuery}
                            handleTagClick={handleTagClick}
                            highlightText={highlightText}
                        />
                    )}
                </div>
            </div>

            {showShareModal && (
                <ShareModal
                    handleCloseShare={() => setShowShareModal(false)}
                    onCopy={() => {
                        setCopySuccess("Copied!");
                        setTimeout(() => setCopySuccess(""), 2000);
                    }}
                    copySuccess={copySuccess}
                    shareUrl={shareUrl}
                />
            )}
            {showTransferModal && (
                <TransferModal
                    handleCloseTransfer={() => setShowTransferModal(false)}
                    scenario={scenario}
                />
            )}
        </>
    );
};

export default ScenarioCard;
