import React from 'react';
import styles from './styles/ProfileSettings.module.css';

const ProfileSettings = () => {
    return (
        <div className={styles.profileContainer}>
            <h2>Profile Settings</h2>
            <form className={styles.form}>
                <div className={styles.formGroup}>
                    <label>Name</label>
                    <input type="text" placeholder="John Doe" />
                </div>
                <div className={styles.formGroup}>
                    <label>Email</label>
                    <input type="email" placeholder="john@example.com" />
                </div>
                <button type="submit" className={styles.saveButton}>
                    Save Changes
                </button>
            </form>
        </div>
    );
};

export default ProfileSettings;